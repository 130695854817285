<template>
  <page-container>
    <page-header title="Albums" />
    <div class="d-flex justify-space-around flex-wrap">
      <template v-for="album in albums">
        <div :key="album.yt_id" class="ma-2 mt-6" style="width: 300px; border: 2px #444 solid;">
          <figure
            style="position: relative; cursor: pointer;"
            @click="playVideo(album)"
            class="thumbnail"
          >
            <v-img
              :width="300"
              :height="140"
              :src="`//img.youtube.com/vi/${album.yt_id}/mqdefault.jpg`"
            ></v-img>
            <div class="centered">
              <v-btn fab dark small color="red" class="elevation-2">
                <v-icon>mdi-play</v-icon>
              </v-btn>
            </div>
          </figure>
          <div
            class="py-2 px-2 text-center"
            style="background: linear-gradient(90deg, #333 0%, #111 50%, #333 100%); color: #CDCDCD;
            font-size: .8rem; text-overflow: ellipsis; white-space: nowrap; overflow: hidden;"
          >
            {{ album.title }}</div>
        </div>
      </template>
    </div>
    <v-dialog
      v-model="showDialog"
      :width="$vuetify.breakpoint.mdAndUp? 840 : 420"
      persistent
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dark>
          <span>{{ currentSong.title }}</span>
          <span v-if="currentSong.channel" class="subtitle-2 grey--text pl-4"
            >℗ {{ currentSong.channel }}</span
          >
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="ma-0 pa-0 text-center">
          <iframe
            :width="$vuetify.breakpoint.mdAndUp? 800 : 400"
            :height="$vuetify.breakpoint.mdAndUp? 500 : 250"
            :src="`https://www.youtube.com/embed/${currentSong.yt_id}?autoplay=1`"
            allow="fullscreen;"
            style="border: none"
          >
          </iframe>
        </v-card-text>
      </v-card>
    </v-dialog>
  </page-container>
</template>

<script>
// Megh Pakhi (2000), Bandhu (2001), Roddur (2002), Nana Ronge (2004), Antomil (2012), Majlis (2017), Kamal Geeti (2018) and Bani Bandana (2019)
export default {
  data: () => ({
    showDialog: false,
    currentSong: {},
    albums: [
      {
        title: 'Megh Pakhi (2000)',
        yt_id: 'PAzRBRz8WZk'
      },
      {
        title: 'Bandhu (2001)',
        yt_id: 'yvcEvMrBFiY'
      },
      {
        title: 'Roddur (2002)',
        yt_id: 'G7VtPrV2emk'
      },
      {
        title: 'Nana Ronge (2004)',
        yt_id: 'KaI-52yzB-o'
      },
      {
        title: 'Antomil (2012)',
        yt_id: '9dZXll4FVb4'
      },
      {
        title: 'Majlis (2017)',
        yt_id: 'C1bvjo8Tkrc'
      },
      {
        title: ' Tara Name (2016)',
        yt_id: 'pDC08anXqOk'
      },
      {
        title: 'Kamal Geeti (2018)',
        yt_id: 'LIDVuGt_6ms'
      },
      {
        title: 'Bani Bandana (2019)',
        yt_id: 'Q6VfgHLr-Gw'
      }
    ]
  }),
  computed: {},
  methods: {
    playVideo (song) {
      this.currentSong = song
      this.showDialog = true
      // window.open('//youtube.com/watch?v=${item.link}', '_blank')
    },
    closeDialog () {
      this.showDialog = false
      setTimeout(() => {
        this.currentSong = {}
      }, 250)
    }
  },
  components: {
    PageContainer: () => import('@/components/reusable/PageContainer'),
    PageHeader: () => import('@/components/reusable/PageHeader')
  }
}
</script>

<style lang="scss" scoped>
.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.thumbnail {
  div:nth-child(1) {
    filter: saturate(0.5);
  }
  &:hover {
    div:nth-child(1) {
      filter: saturate(1);
    }
  }
}
</style>
