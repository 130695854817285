import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('page-container',[_c('page-header',{attrs:{"title":"Albums"}}),_c('div',{staticClass:"d-flex justify-space-around flex-wrap"},[_vm._l((_vm.albums),function(album){return [_c('div',{key:album.yt_id,staticClass:"ma-2 mt-6",staticStyle:{"width":"300px","border":"2px #444 solid"}},[_c('figure',{staticClass:"thumbnail",staticStyle:{"position":"relative","cursor":"pointer"},on:{"click":function($event){return _vm.playVideo(album)}}},[_c(VImg,{attrs:{"width":300,"height":140,"src":`//img.youtube.com/vi/${album.yt_id}/mqdefault.jpg`}}),_c('div',{staticClass:"centered"},[_c(VBtn,{staticClass:"elevation-2",attrs:{"fab":"","dark":"","small":"","color":"red"}},[_c(VIcon,[_vm._v("mdi-play")])],1)],1)],1),_c('div',{staticClass:"py-2 px-2 text-center",staticStyle:{"background":"linear-gradient(90deg, #333 0%, #111 50%, #333 100%)","color":"#CDCDCD","font-size":".8rem","text-overflow":"ellipsis","white-space":"nowrap","overflow":"hidden"}},[_vm._v(" "+_vm._s(album.title))])])]})],2),_c(VDialog,{attrs:{"width":_vm.$vuetify.breakpoint.mdAndUp? 840 : 420,"persistent":"","transition":"dialog-bottom-transition"},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c(VCard,[_c(VToolbar,{attrs:{"dark":""}},[_c('span',[_vm._v(_vm._s(_vm.currentSong.title))]),(_vm.currentSong.channel)?_c('span',{staticClass:"subtitle-2 grey--text pl-4"},[_vm._v("℗ "+_vm._s(_vm.currentSong.channel))]):_vm._e(),_c(VSpacer),_c(VBtn,{attrs:{"icon":"","dark":""},on:{"click":_vm.closeDialog}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),_c(VCardText,{staticClass:"ma-0 pa-0 text-center"},[_c('iframe',{staticStyle:{"border":"none"},attrs:{"width":_vm.$vuetify.breakpoint.mdAndUp? 800 : 400,"height":_vm.$vuetify.breakpoint.mdAndUp? 500 : 250,"src":`https://www.youtube.com/embed/${_vm.currentSong.yt_id}?autoplay=1`,"allow":"fullscreen;"}})])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }